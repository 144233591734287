import "core-js/modules/es.array.push.js";
import store from "@/state/store.js";
let $ = require("jquery");
import ImportContactFromExcel from "./importContactFromExcel";
export default {
  name: "groups",
  components: {
    ImportContactFromExcel
  },
  data() {
    return {
      selectGroupForm: {
        group_id: ""
      },
      selectgrouprules: {
        group_id: [{
          required: true,
          message: this.$t('misc.form.required'),
          trigger: "change"
        }]
      },
      fileList: [],
      groupLoading: false,
      groupError: "",
      type: "alert-danger",
      groupSuccess: "",
      edittingGroup: {},
      mainModalType: "",
      importExcelContentType: "",
      groupFormType: {
        title: this.$t('dashboard.groups.create_new'),
        btnTitle: this.$t('dashboard.groups.create_new'),
        type: "new"
      },
      groupFormData: {
        name: "",
        group_id: "",
        key: ""
      },
      groupRules: {
        name: [{
          required: true,
          message: this.$t('misc.form.required'),
          trigger: "change"
        }]
      },
      isTableLoading: false,
      page: 1,
      total: 0,
      groups: [],
      pageSize: 0,
      initLoading: true
    };
  },
  mounted() {
    this.isTableLoading = true;
    this.initLoading = true;
    this.fetchSpecificPage(1);
  },
  computed: {
    fetchedGroups() {
      return store.getters["groups/all_groups"];
    },
    refreshGroup() {
      return store.getters["uistate/getRefreshGroup"];
    }
  },
  methods: {
    viewContacts(group) {
      store.dispatch("contacts/setSingleGroupWithContacts", group);
      this.$router.push(`/group/${group.id}/contacts`).catch(err => {
        if (err.name !== "NavigationDuplicated") throw err;
      });
    },
    handleError(err, file, fileLis) {
      this.$message.error(this.$t('misc.general_error'));
    },
    handleSuccess(file) {
      this.groupSuccess = this.$t('dashboard.groups.group_success');
    },
    nextToimportGroup(formName) {
      this.importExcelContentType = "excelimport";
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.importExcelContentType = "excelimport";
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    selectGroup() {
      this.importExcelContentType = "selectgroup";
    },
    cancelModal() {
      store.dispatch("uistate/addGroupFormData", {});
      let element = this.$refs.importExcel.$el;
      $(element).modal("hide");
    },
    addNewGroup(type) {
      if (type === "new") {
        store.dispatch("uistate/addGroupFormData", {
          title: this.$t('dashboard.groups.create_new'),
          mainModalType: "new",
          type: "new",
          btnTitle: this.$t('dashboard.groups.create_new')
        });
      } else {
        store.dispatch("uistate/addGroupFormData", {
          title: "",
          mainModalType: "import",
          type: "import",
          btnTitle: this.$t('dashboard.contacts.import_excel_sheet'),
          importExcelContentType: "main"
        });
      }
      let element = this.$refs.importExcel.$el;
      $(element).modal({
        backdrop: "static",
        keyboard: false,
        show: true
      });
    },
    resetGroupState() {
      this.groupFormType = {};
      this.groupFormData.name = null;
      this.groupSuccess = "";
      this.groupError = "", this.groupLoading = false;
    },
    hideGroupModal() {
      this.resetGroupState();
      this.groupSuccess = "";
      $("#groupmodal").modal("hide");
    },
    deleteGroupAction(group, key) {
      store.dispatch("uistate/addGroupFormData", {
        title: this.$t('dashboard.groups.delete'),
        btnTitle: this.$t('misc.button.delete'),
        mainModalType: "new",
        type: "delete",
        group_id: group.id,
        key: key,
        name: group.name
      });
      this.groupFormData = {
        name: group.name,
        key: key,
        group_id: group.id
      };
      let element = this.$refs.importExcel.$el;
      $(element).modal("show");
    },
    editGroupAction(group, key) {
      store.dispatch("uistate/addGroupFormData", {
        title: this.$t('dashboard.groups.edit'),
        btnTitle: this.$t('dashboard.groups.edit'),
        mainModalType: "new",
        type: "edit",
        group_id: group.id,
        key: key,
        name: group.name
      });
      this.groupFormData = {
        name: group.name,
        key: key,
        group_id: group.id
      };
      let element = this.$refs.importExcel.$el;
      $(element).modal("show");
    },
    // API ACTIONS
    deleteGroup() {
      this.groupError = "";
      this.groupLoading = true;
      store.dispatch("groups/deleteGroup", this.edittingGroup).then(response => {
        this.groupSuccess = response.data.message;
        this.groupLoading = false;
      }).catch(err => {
        var _err$response;
        this.groupLoading = false;
        if (((_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
          this.groupError = this.$t('misc.network_error');
        }
        if (err.response && err.response.status === 404) {
          this.groupError = err.response.data.message;
        }
      });
    },
    editGroup(formName) {
      this.groupError = "";
      this.groupLoading = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          // return;
          store.dispatch("groups/updateGroup", this.groupFormData).then(response => {
            this.groupSuccess = response.data.message;
            this.groupLoading = false;
            this.groupFormData.name = "";
          }).catch(err => {
            var _err$response2;
            this.groupLoading = false;
            if (((_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) == undefined) {
              this.groupError = this.$t('misc.network_error');
            }
            if (err.response && err.response.status === 404) {
              this.groupError = err.response.data.message;
            }
          });
        } else {
          this.groupLoading = false;
          return false;
        }
      });
    },
    createNewGroup(formName) {
      this.groupError = "";
      this.groupLoading = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          store.dispatch("groups/createGroup", this.groupFormData).then(response => {
            this.groupSuccess = response.data.message;
            this.groupLoading = false;
            this.groupFormData.name = null;
            // Call Refresh Here
          }).catch(err => {
            var _err$response3;
            this.groupLoading = false;
            if (((_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : _err$response3.data) == undefined) {
              this.groupError = this.$t('misc.network_error');
            }
            if (err.response && err.response.status === 404) {
              this.groupError = err.response.data.message;
            }
          }).finally(() => {
            this.fetchSpecificPage(this.page);
          });
        } else {
          this.groupLoading = false;
          return false;
        }
      });
    },
    refreshGroupEmit() {
      this.$emit("refreshContactsUpdatedGroup");
      store.dispatch("uistate/setContactActionsDisabled", true);
      store.dispatch("uistate/setRefreshGroup", true);
    },
    handleCurrentChange(val) {
      this.fetchSpecificPage(val);
      this.page = val;
    },
    fetchSpecificPage(page = 1) {
      this.isTableLoading = true;
      store.dispatch("groups/listGroups", page).then(response => {
        store.dispatch("uistate/setContactActionsDisabled", false);
        let RetrievedData = response.data.data;
        this.groups = RetrievedData.data;
        this.total = RetrievedData.total;
        this.pageSize = RetrievedData.per_page;
      }).finally(() => {
        this.isTableLoading = false;
        this.initLoading = false;
      });
    }
  },
  watch: {
    refreshGroup(newValue, oldValue) {
      if (newValue == true) {
        this.fetchSpecificPage();
        store.dispatch("uistate/setRefreshGroup", false);
      }
    }
  }
};