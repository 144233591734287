<template>
  <div class="scrollable">
    <div class="row justify-content-between" v-if="!fromGroup">
      <div class="col-sm-12 col-md-7">
        <Contacts
          v-on:needsRefreshContacts="heardEmit"
          :key="triggerRefreshOnComponent" />
      </div>
      <div class="col-sm-12 col-md-5">
        <Groups v-on:refreshContactsUpdatedGroup="heardEmit" />
      </div>
    </div>
  </div>
</template>
<script>
  const $ = require("jquery");
  import appConfig from "@/app.config";
  import Groups from "./Groups.vue";
  import Contacts from "./Contacts.vue";
  import ImportContactFromExcel from "./importContactFromExcel.vue";
  import store from "@/state/store.js";

  export default {
    data() {
      return {
        triggerRefreshOnComponent: 0,
      };
    },
    name: "contacts-and-groups",
    components: {
      Groups,
      Contacts,
      ImportContactFromExcel,
    },
    page: {
      title: "Contacts",
      meta: [{ name: "description", content: appConfig.description }],
    },
    computed: {
      fromGroup() {
        return store.getters["contacts/fromGroup"];
      },
    },
    methods: {
      forceRender: function () {
        this.triggerRefreshOnComponent = this.triggerRefreshOnComponent + 1;
      },
      heardEmit: function () {
        this.forceRender();
      },
    },
    mounted() {
      // Analytics
      this.$mixpanel.track("Contacts Page");
      if (this.$store.getters["auth/currentUser"].country === "CI") {
        this.$mixpanelFranco.track("Contacts Page");
      }
    },
  };
</script>
<style scoped>
  @import url("../../../styles/__contacts.scss");

  .scrollable {
    overflow-y: hidden;
    height: 88vh;
    overflow-x: hidden;
    /* padding-bottom: calc(20vh - 100px); */
  }

  @media screen and (max-width: 768px) {
    .scrollable {
      overflow-y: auto;
    }

    .contains-message-buttons {
      flex-direction: column;
    }

    *::-webkit-scrollbar {
      width: 4px;
    }
  }
</style>
