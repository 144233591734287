import "core-js/modules/es.array.push.js";
import store from "@/state/store.js";
import $ from "jquery";
import contactModal from "./createNewContact.vue";
import formatInputDate from "@/helpers/formatInputDate";
import importContactFromExcel from "./importContactFromExcel.vue";
import downloadcsv from "../../../helpers/downloadcsv";
export default {
  name: "contacts",
  components: {
    importContactFromExcel,
    contactModal
  },
  data() {
    return {
      triggerRefreshOnComponent: 0,
      showModal: false,
      exportLoading: false,
      loading: false,
      currentModal: "",
      errors: "",
      page: 1,
      pageSize: 50,
      total: 0,
      deletEditModalContent: {},
      rules: {
        firstname: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }],
        lastname: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }],
        // email: [
        //     {required: true, message: 'Please this field is required', trigger: 'change' },
        //     { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
        // ],
        phone: [{
          pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/g,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }, {
          min: 10,
          max: 15,
          message: this.$t("misc.form.required"),
          trigger: "blur"
        }]
        // date_of_birth:[
        //     {required: true, message: "Date of birth is required.", trigger: 'change'},
        // ]
      },
      // ! Current implementation revolves using filter to remove contacts with no group
      contacts: [],
      fetchingData: false,
      formData: {
        firstname: "",
        lastname: "",
        name: "",
        email: "",
        phone: "",
        id: "",
        key: "",
        date_of_birth: null
      },
      isEdit: true,
      search: "",
      contactError: [],
      contactSuccess: "",
      success: "",
      conctactLoading: false,
      type: "alert-danger",
      modalData: {
        title: "",
        formData: {},
        btnTitle: ""
      },
      selectedContact: {
        key: "",
        contact_id: "",
        group_id: ""
      },
      selectedKeys: [],
      contactDetail: {},
      formData: {},
      tableSelectContacts: [],
      tableLoadingData: false,
      buttonSearchLoading: false
    };
  },
  created() {
    this.fetchingData = true;
    store.dispatch("contacts/listContacts", 1).then(response => {
      this.fetchingData = false;
      let RetrievedData = response.data.data;
      this.contacts = RetrievedData.data.map(obj => ({
        ...obj,
        active: false
      })).filter(obj => obj.group !== null);
      this.total = RetrievedData.total;
    }).catch(() => this.fetchingData = false);
  },
  computed: {
    searching() {
      if (!this.search) {
        this.total = this.contactsCount;
        return this.contacts;
      }
      this.page = 1;
      return this.contacts;
    },
    displayData() {
      this.total = this.contactsCount; //this.searching.length;
      return this.searching;
    },
    contactsCount() {
      return this.total;
    },
    dynamicHeight() {
      if (screen.height < 749) {
        return "55vh";
      } else {
        return "60vh";
      }
    },
    showImportOnExcel() {
      if (screen.width <= 768) {
        return true;
      }
      return false;
    },
    getDisableButtons() {
      return store.getters["uistate/contactActionsDisabled"];
    }
  },
  methods: {
    exportReport() {
      this.exportLoading = true;
      store.dispatch("contacts/exportContact", this.$route.params.id).then(data => {
        downloadcsv(data, `${localStorage.getItem("userName").replace(/ /g, "_")}_Contacts.csv`);
      }).finally(() => this.exportLoading = false);
    },
    hideMainModal(type = "something") {
      $("#deletecontact").modal("hide");
      if (type == "reload") {
        // Refresh data after individual contact is deleted
        this.refreshTable(true);
        // setTimeout(() => { window.location.reload() }, 100)
      }
      this.contactSuccess = null;
      this.contactError = [];
      this.errors = null;
      this.success = null;
      this.currentModal = "";
      this.isEdit = true;
    },
    updateContact(contact) {
      this.contacts.push(contact);
      // setTimeout(() => {
      //     window.location.reload()
      // }, 100)
      // this.forceRerender()
    },
    fetchSpecificPage(page) {
      this.tableLoadingData = true;
      store.dispatch("contacts/listContacts", page).then(response => {
        this.tableLoadingData = false;
        let data = response.data.data.data;
        this.contacts = data.map(obj => ({
          ...obj,
          active: false
        })).filter(obj => obj.group !== null);
      }).catch(() => this.fetchingData = false);
    },
    handleCurrentChange(val) {
      this.fetchSpecificPage(val);
      this.page = val;
    },
    handleRowStyle({
      row,
      rowIndex
    }) {
      return this.contacts && this.contacts[rowIndex].active ? {
        background: "rgba(207, 214, 233, 0.19)"
      } : "";
    },
    cancelDeleteMultiple() {
      this.contacts.forEach(contact => contact.active = false);
      this.tableSelectContacts = [];
      this.selectedKeys = [];
    },
    handleSelect(row) {
      this.contacts[row.$index].active = !this.contacts[row.$index].active;
      this.tableSelectContacts.push(row.row.id);
    },
    handleDeselect(row) {
      this.contacts[row.$index].active = !this.contacts[row.$index].active;
      this.tableSelectContacts.pop(row.row.id);
    },
    addNewContact() {
      let element = this.$refs.contactModal.$el;
      $(element).modal("show");
      store.dispatch("uistate/addContactFormData", {
        title: this.$t("dashboard.contacts.add_new"),
        formData: {},
        btnTitle: this.$t("dashboard.contacts.add_contact")
      });
    },
    back() {
      this.deletEditModalContent = {
        title: this.$t("dashboard.contacts.editng_heading"),
        text: `${this.contactDetail.group.name}`,
        type: "continue_edit",
        icon: require("../../../assets/edit__.svg")
      };
      this.currentModal = "delete";
    },
    importFromExcel() {
      store.dispatch("uistate/addGroupFormData", {
        title: "",
        mainModalType: "import",
        type: "import",
        btnTitle: this.$t("dashboard.contacts.import_excel_sheet"),
        importExcelContentType: "main"
      });
      let element = this.$refs.importExcel.$el;
      $(element).modal({
        backdrop: "static",
        keyboard: false,
        show: true
      });
    },
    cancelImportFromExcel() {
      store.dispatch("uistate/addGroupFormData", {});
      let element = this.$refs.importExcel.$el;
      $(element).modal("hide");
    },
    editForm() {
      this.currentModal = "detail";
      this.isEdit = false;
      this.loading = false;
      this.errors = "";
      this.success = "";
    },
    deleteAction() {
      this.loading = false;
      this.errors = "";
      this.success = "";
      this.currentModal = "delete";
      this.deletEditModalContent = {
        title: this.$t("dashboard.contacts.delete_heading"),
        text: this.$t("dashboard.contacts.contact_group", {
          group: this.contactDetail.group.name
        }),
        type: "delete",
        icon: require("../../../assets/Delete.svg")
      };
    },
    continueEdit() {
      this.deletEditModalContent = {
        title: this.$t("dashboard.contacts.editng_heading"),
        text: this.$t("dashboard.contacts.contact_group", {
          group: this.contactDetail.group.name
        }),
        type: "continue_edit",
        icon: require("../../../assets/edit__.svg")
      };
      this.currentModal = "delete";
    },
    opendModal({
      contact,
      key,
      type
    }) {
      // reset error state
      this.errors = "";
      $("#deletecontact").modal({
        backdrop: "static",
        keyboard: false,
        show: true
      });
      if (type === "delete") {
        this.deletEditModalContent = {
          title: this.$t("dashboard.contacts.delete_heading"),
          text: this.$t("dashboard.contacts.contact_group", {
            group: contact.group.name
          }),
          type: "delete",
          icon: require("../../../assets/Delete.svg")
        };
        this.currentModal = "delete";
      } else if (type == "edit") {
        this.deletEditModalContent = {
          title: this.$t("dashboard.contacts.editng_heading"),
          text: this.$t("dashboard.contacts.contact_group", {
            group: contact.group.name
          }),
          type: "continue_edit",
          icon: require("../../../assets/edit__.svg")
        };
        this.currentModal = "delete";
      } else if (type === "detail") {
        this.currentModal = "detail";
      }
      this.contactDetail = contact;
      this.selectedContact = {
        key: key,
        contact_id: contact.id,
        group_id: contact.group_id
      };
      // update edit form component
      let date = contact.date_of_birth == null || contact.date_of_birth == "NaN-NaN-NaN" || contact.date_of_birth == "0000-00-00" ? null : formatInputDate(contact.date_of_birth);
      this.formData = {
        firstname: contact.firstname,
        lastname: contact.lastname,
        email: contact.email,
        phone: contact.phone,
        // date_of_birth: Date(contact.date_of_birth),
        date_of_birth: date,
        id: contact.id,
        key: key
      };
    },
    deleteMultiple() {
      this.errors = "";
      this.success = "";
      this.loading = true;
      let payload = {
        contact_ids: this.tableSelectContacts.toString(),
        key: this.selectedContact.key,
        group_id: this.selectedContact.group_id
      };
      store.dispatch("contacts/deleteContacts", payload).then(response => {
        this.loading = false;
        this.success = response.data.message;
        setTimeout(() => {
          this.refreshTable(true);
        }, 100);
      }).catch(err => {
        var _err$response;
        this.loading = false;
        if (((_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
          this.errors = this.$t("misc.network_error");
        }
        if (err.response && err.response.status === 404) {
          this.loading = false;
          this.errors = err.response.data.data.error;
        }
      });
    },
    deleteContact() {
      this.errors = "";
      this.loading = true;
      let payload = {
        contact_id: this.selectedContact.contact_id,
        key: this.selectedContact.key,
        group_id: this.selectedContact.group_id
      };
      store.dispatch("contacts/deleteContact", payload).then(response => {
        if (response.data.message == "Contact Exist in other groups") {
          this.errors = response.data.message;
        } else {
          this.success = response.data.message;
          this.contacts.splice(this.selectedContact.key, 1);
          // Refresh everycomponent here
          this.refreshTable(true);
        }
        this.loading = false;
      }).catch(err => {
        var _err$response2;
        this.loading = false;
        if (((_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) == undefined) {
          this.errors = this.$t("misc.network_error");
        }
        if (err.response && err.response.status === 404) {
          this.loading = false;
          this.errors = err.response.data.data.error;
        }
      });
    },
    save(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.errors = "";
          this.loading = true;
          let {
            date_of_birth,
            email,
            phone,
            firstname,
            lastname,
            id,
            key
          } = this.formData;
          date_of_birth == null ? null : formatInputDate(contact.date_of_birth);
          let payload = {
            data: {
              firstname: firstname,
              lastname: lastname,
              date_of_birth: date_of_birth,
              phone: phone,
              email: email
            },
            id: id,
            key: key
          };
          store.dispatch("contacts/updateContact", payload).then(response => {
            let data = response.data.data;
            this.contacts[this.formData.key] = data;
            this.contacts = [...this.contacts];
            this.loading = false;
            this.success = response.data.message;
          }).catch(err => {
            var _err$response3;
            this.loading = false;
            if (((_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : _err$response3.data) == undefined) {
              this.errors = this.$t("misc.network_error");
            }
            if (err.response && err.response.status === 404) {
              this.errors = err.response.data.data.error;
            }
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    refreshTable(shouldRefreshData = false) {
      this.triggerRefreshOnComponent += 1;
      store.dispatch("uistate/setContactActionsDisabled", true);
      this.hideMainModal();
      if (shouldRefreshData === true) {
        this.fetchDataAfterRefresh();
        this.$emit("refreshGroup");
      }
      store.dispatch("uistate/setRefreshGroup", true);
    },
    fetchDataAfterRefresh() {
      store.dispatch("contacts/listContacts", 1).then(response => {
        this.tableLoadingData = false;
        this.fetchingData = false;
        let RetrievedData = response.data.data;
        this.contacts = RetrievedData.data.map(obj => ({
          ...obj,
          active: false
        })).filter(obj => obj.group !== null);
        this.total = RetrievedData.total;
      }).catch(() => this.fetchingData = false).finally(() => {
        store.dispatch("uistate/setContactActionsDisabled", false);
        store.dispatch("uistate/setRefreshGroup", true);
      });
    },
    searchContact() {
      this.errors = "";
      this.buttonSearchLoading = true;
      this.tableLoadingData = true;
      let payload = {
        search: this.search
      };
      store.dispatch("contacts/searchContact", payload).then(response => {
        this.buttonSearchLoading = false;
        this.contacts = response.data;
        this.tableLoadingData = false;
        this.total = this.contacts.length;
        if (this.response.data.length == 0) {
          this.$notify({
            title: this.$t("misc.toast.alert"),
            message: this.$t("dashboard.contacts.no_result_title"),
            type: "warning"
          });
        }
      }).catch(err => {
        var _err$response4;
        this.buttonSearchLoading = false;
        if (((_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : _err$response4.data) == undefined) {
          this.errors = this.$t("misc.network_error");
        }
        if (err.response && err.response.status === 404) {
          this.buttonSearchLoading = false;
          this.errors = err.response.data.data.error;
        }
      });
    },
    testChange(value) {
      if (value == "") {
        this.fetchDataAfterRefresh();
      } else {
        return;
      }
    },
    addedSuccess() {
      this.refreshTable(true);
    }
  }
};